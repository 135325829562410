"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllMenuPages = exports.doLoginAsEmployee = exports.doLoginAsFornecedor = exports.doLoginAsComprador = void 0;
const core_1 = require("./core");
function doLoginAsComprador(login, senha) {
    return core_1.post('Acesso', 'efetuarLoginComprador', {
        login,
        senha
    })
        .then(({ dados, token }) => {
        return {
            token,
            current: dados[0]
        };
    });
}
exports.doLoginAsComprador = doLoginAsComprador;
function doLoginAsFornecedor(login, senha) {
    return core_1.post('Acesso', 'efetuarLoginFornecedor', {
        login,
        senha
    })
        .then(({ dados, token }) => {
        return {
            token,
            current: dados[0]
        };
    });
}
exports.doLoginAsFornecedor = doLoginAsFornecedor;
function doLoginAsEmployee(login, senha) {
    return core_1.post('Acesso', 'efetuarLogin', {
        login,
        senha
    })
        .then(({ dados, token }) => {
        return {
            token,
            current: dados[0]
        };
    });
}
exports.doLoginAsEmployee = doLoginAsEmployee;
function getAllMenuPages() {
    return core_1.get('Menu', 'listaPorPerfil')
        .then(({ dados }) => dados);
}
exports.getAllMenuPages = getAllMenuPages;
