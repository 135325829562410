import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
//funcionalidades
import relatorios from './modulos/relatorios';
import alterarSenha from "./modulos/alterarSenha";
import parametros from "./modulos/parametros";
import funcionarios from "./modulos/funcionarios";
import painel from "./modulos/painel";
import gestaoAcesso from './modulos/gestaoAcesso';
import fornecedor from "./modulos/fornecedor";
import comprador from './modulos/comprador';
import setor from './modulos/setor';
import porte from './modulos/porte';
import ramoAtuacao from './modulos/ramoAtuacao';
import atividadeEconomica from './modulos/atividadeEconomica';
import oferta from './modulos/oferta';
import demanda from './modulos/demanda';
import deuMatch from './modulos/deuMatch';
import chat from './modulos/chat';
import ofertaFornecedor from './modulos/ofertaFornecedor';
import dadosFornecededor from './modulos/dadosFornecededor';
import dadosComprador from './modulos/dadosComprador';
import meuCadastro from './modulos/meuCadastro';
import AlterarSenhaResp from './modulos/AlterarSenhaResp';

Vue.use(VueRouter)

const routes = [
    ...relatorios,
    ...alterarSenha,
    ...parametros,
    ...funcionarios,
    ...painel,
    ...gestaoAcesso,
    ...fornecedor,
    ...comprador,
    ...setor,
    ...porte,
    ...ramoAtuacao,
    ...atividadeEconomica,
    ...oferta,
    ...demanda,
    ...chat,
    ...deuMatch,
    ...ofertaFornecedor,
    ...dadosFornecededor,
    ...dadosComprador,
    ...meuCadastro,
    ...AlterarSenhaResp,
    {
        name: 'Unauthorized',
        path: '/unauthorized',
        meta: {
            title: 'Sem Autorização',
            unauthorized: true,
        },
        component: () => import('@/views/Unauthorized')
    },
    {
        name: 'Login',
        path: '/login',
        props: {
            type: 'usuario'
        },
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Login')
    },
    // {
    //     name: 'Login2',
    //     path: '/login2',
    //     props: {
    //         type: 'usuario'
    //     },
    //     meta: {
    //         loginPage: true
    //     },
    //     component: () => import('@/views/Login/Login2')
    // },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    await store.restored;
    const {meta} = to;

    function isGoingToLoginPage() {
        return meta.hasOwnProperty('loginPage') && meta.loginPage;
    }

    function isGoingToUnauthorizedPage() {
        return meta.hasOwnProperty('unauthorized') && meta.unauthorized;
    }

    async function handleIsAuthenticated() {
        const defaultHomePage = await store.dispatch('auth/getDefaultHomePage');
        if (isGoingToLoginPage()) {
            next({
                name: defaultHomePage
            });
        } else {
            const authorizedLinks = [...store.getters['auth/authorizedLinks']];
            if (isGoingToUnauthorizedPage()) {
                next();
            } else {
                if (authorizedLinks.indexOf(to.path) > -1) {
                    next();
                } else {
                    if (to.path === '/') {
                        next({
                            name: defaultHomePage
                        })
                    } else {
                        next('/unauthorized')
                    }
                }
            }
        }
    }

    async function handleNotAuthenticated() {
        if (isGoingToLoginPage()) {
            next();
        } else {
            const defaultLoginPage = await store.dispatch('auth/getDefaultLoginPage');
            next({
                name: defaultLoginPage
            })
        }
    }

    if (store.getters['auth/isAuthenticated']) {
        await handleIsAuthenticated();
    } else {
        await handleNotAuthenticated();
    }

})

export default router
