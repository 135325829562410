<template>
  <v-app-bar app height="61px" color="bb elevation-0">
    <v-app-bar-nav-icon color="white" @click="toggle" v-if="$vuetify.breakpoint.mobile"/>
    <v-app-bar-title>
      {{ title }}
    </v-app-bar-title>
    <div class="busca pa-0" v-if="!$vuetify.breakpoint.mobile">
      <global-search class="mx-4 my-2"/>
    </div>
    <v-menu
        v-if="!$vuetify.breakpoint.mobile"
        bottom
        :close-on-click="true"
        :close-on-content-click="true"
        :offset-y="true"
        origin="center center"
        transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            text
            v-bind="attrs"
            v-on="on"
        >
          <v-avatar
            size="30px"
            class="mr-2 white-border"
            align-left>
            <img 
              v-if="avatar"
              src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
              alt="Avatar"
            >
            <v-icon v-else color="white">mdi-account</v-icon>
          </v-avatar>
          <div class="name-field">
            {{ getNomeLogado }}<!-- {{ user.nome }} -->
            <div class="perfil-field">
              {{ descNivelUsuarioLogado }}
            </div>
          </div>
          
          <v-icon right class="ml-2" color="white">mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list class="pa-0 ma-0">
        <v-list-item @click="onClickLogout">
          Sair
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import GlobalSearch from "./GlobalSearch";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "AppToolbar",
  components: {GlobalSearch},
  data() {
    return {
      avatar: false,
    }
  },
  computed: {
    ...mapState('route', ['name','meta']),
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
    title() {
      return this.meta?.title || this.name;
    },
    getNomeLogado(){
      return this.user.nome;
    },
    descNivelUsuarioLogado(){
      return this.nivelUsuarioLogado + (this.isFornecedorOuComprador(this.nivelUsuarioLogado) ? ": " + this.user.nomeResponsavel : "");
    }
  },
  methods: {
    ...mapActions('menu',['toggle']),
    ...mapActions('auth',['doLogout', 'getDefaultLoginPage']),
    async onClickLogout() {
      await this.doLogout();
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-toolbar__content {
    background-color: #1374B9 !important;
  }
  // .v-app-bar--fixed {
  //   background-color: #f5f5f5 !important;
  // }

  // // .v-toolbar__content .v-list {
  // //   background-color: #f5f5f5 !important;
  // // }
  .v-app-bar-nav-icon {
    color: white !important;
  }
  .v-app-bar-title {
    flex: 1;
    color: white;
    .v-app-bar-title__content {
      width: 100% !important;
    }
  }
  .busca {
    width: 40%;
  }
  .name-field {
    max-width: 300px;
    color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left !important;
  }

  .perfil-field {
    max-width: 300px;
    color: rgb(255, 208, 0);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: capitalize !important;
  }

  .white-border {
    border: 2px solid white;
  }  
}

</style>