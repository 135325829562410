"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findFormItemDominio = exports.findItemsDominio = exports.ItemDeDominio = void 0;
const core_1 = require("./core");
var ItemDeDominio;
(function (ItemDeDominio) {
    ItemDeDominio["NIVEL_ACESSO_USUARIO"] = "nivelAcessoUsuario";
    ItemDeDominio["ARR_FORMA_PGTO"] = "arrFormaPgto";
    ItemDeDominio["ARR_FORMA_PGTO_ABREV"] = "arrFormaPgtoAbrev";
    ItemDeDominio["ARR_DIAS_SEMANA"] = "arrDiasSemana";
    ItemDeDominio["ARR_MES"] = "arrMes";
    ItemDeDominio["STATUS_PADRAO"] = "statusPadrao";
    ItemDeDominio["TIPO_PLANO"] = "tipoPlano";
    ItemDeDominio["ARR_TIPO_PESSOA"] = "arrTipoPessoa";
    ItemDeDominio["FLG_PADRAO"] = "flgPadrao";
    ItemDeDominio["ARR_SEXO"] = "arrSexo";
    ItemDeDominio["STS_PAGAMENTO"] = "stsPagamento";
    ItemDeDominio["COMBO_ESTADO"] = "comboEstado";
    ItemDeDominio["ARR_DATA_FILTRO_RECB"] = "arrDataFiltroReceb";
    ItemDeDominio["TIPO_ITEM"] = "arrTipoItem";
    ItemDeDominio["REGIOES_BRASIL"] = "regioesBrasil";
})(ItemDeDominio = exports.ItemDeDominio || (exports.ItemDeDominio = {}));
function findItemsDominio(item) {
    return core_1.get('itemDominio', item)
        .then(({ dados }) => dados.map(item => ({
        key: item.key,
        valor: item.valor
    })));
}
exports.findItemsDominio = findItemsDominio;
function findFormItemDominio(page = 1, search) {
    return core_1.get('itemDominio', search, { pg: page })
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findFormItemDominio = findFormItemDominio;
