import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    ALTERARSENHARESP: 'AlterarSenhaResp'
}

export default [
    {
        name: ROUTER_NAMES.ALTERARSENHARESP,
        path: '/alterar-senha-resp',
        meta: {
            menu: MENUS.MEUSDADOS,
            title: 'Alterar Senha',
            searchAlias: 'Alterar Senha',
            searchDescription: 'Alterar Senha',
            searchTerms: ['alterar','senha'],
            hasRole: 'role/alterarSenhaResp'
        },
        component: () => import('@/views/AlterarSenha/resp')
    },
]