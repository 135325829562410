<template>
  <v-navigation-drawer app
                       v-model="opened"
                       color="white">

    <v-list class="pa-0">

      <v-list-item class="fundo-logo" style="height: 61px">
        <v-list-item-title>
          <app-logo height="47px"/>
        </v-list-item-title>
      </v-list-item>
      <v-divider/>
    </v-list>
    <!-- <div class="ma-4 mb-3 d-flex align-center justify-start" style="height: 31px;">
      <p class="ma-0 body-2 field-name">
        <strong>
          Olá {{ userName }}
        </strong>
      </p>
    </div>
    <v-divider/> -->
    <v-list class="menu-list" nav dense>
      <v-list-group v-for="m of menu" :key="m.title" :value="false">
        <template v-slot:activator>
          <v-list-item-title>
            <v-icon color="#FFCA01">{{m.icon}}</v-icon> {{ m.title }}
          </v-list-item-title>
        </template>
        <v-list-item link class="ml-6" v-for="menuCadastroItem of m.childrens" :prepend-icon="menuCadastroItem.icon"
                     :to="menuCadastroItem.link"
                     :key="menuCadastroItem.link">
          <v-list-item-title>
            <v-icon color="#1374B9">{{menuCadastroItem.icon}}</v-icon> {{ menuCadastroItem.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group class="hide-caret" @click="onClickLogout">
        <template v-slot:activator>
          <v-list-item>
            <v-icon left>mdi-logout</v-icon>
            <v-list-item-title> Sair</v-list-item-title>
          </v-list-item>
        </template>        
      </v-list-group>
    </v-list>
    <v-list class="pa-0">
      <v-divider/>
      <v-list-item @click="onClickLogout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Sair
            <div class="versao">Versão: {{appVersion}} </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-navigation-drawer>
</template>

<script>
// import GlobalSearch from "./GlobalSearch";
import AppLogo from "./AppLogo";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "AppMenu",
  components: {AppLogo},
  data() {
    return {
      appVersion: PACKAGE_VERSION,
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated', 'menu', 'authorizedLinks']),
    opened: {
      get () {
        return this.$store.state.menu.opened
      },
      set(value) {
        this.SET_OPENED(value);
      }
    },
    userName: {
      get() {
        return this.user.nome;
      }
    }
  },
  methods: {
    ...mapMutations('menu',['SET_OPENED']),
    ...mapActions('auth',['doLogout', 'getDefaultLoginPage']),
    async onClickLogout() {
      await this.doLogout();
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .fundo-logo {
      background-color: #075084 !important;
    }
    .menu-list {
      flex: 1;
      overflow: auto;
    }

  }

  .field-name {
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* Esconde a seta padrão (caret) apenas para o grupo com a classe 'hide-caret' */
  .hide-caret .v-list-group__header .v-list-group__header--active .v-icon,
  .hide-caret .v-list-group__header .v-icon:not(.mdi-logout) {
    display: none !important;
  }

}
.versao {
  font-size: 0.6rem;
  text-align: left;
}
</style>