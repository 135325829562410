import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    DADOSFORNECEDOR: 'DadosFornecedor'
}

export default [
    {
        name: ROUTER_NAMES.DADOSFORNECEDOR,
        path: '/dados-fornecedor',
        meta: {
            menu: MENUS.MEUSDADOS,
            title: 'Dados do Fornecedor',
            searchAlias: 'DadosFornecedor',
            searchDescription: 'Veja dados do Fornecedor cadastrados',
            searchTerms: ['Dados','Fornecedor'],
            hasRole: 'role/dadosFornecedor'
        },
        component: () => import('@/views/Fornecedor/dadosFornecedor')
    },
]