import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    MEUCADASTRO: 'MeuCadastro'
}

export default [
    {
        name: ROUTER_NAMES.MEUCADASTRO,
        path: '/meu-cadastro',
        meta: {
            menu: MENUS.MEUSDADOS,
            title: 'Meu Cadastro',
            searchAlias: 'MeuCadastro',
            searchDescription: 'Veja seus dados cadastradas',
            searchTerms: ['Meu','Cadastro'],
            hasRole: 'role/meuCadastro'
        },
        component: () => import('@/views/Contato/meuCadastro')
    },
]