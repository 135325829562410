import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    DADOSCOMPRADOR: 'DadosComprador'
}

export default [
    {
        name: ROUTER_NAMES.DADOSCOMPRADOR,
        path: '/dados-comprador',
        meta: {
            menu: MENUS.MEUSDADOS,
            title: 'Dados do Comprador',
            searchAlias: 'DadosComprador',
            searchDescription: 'Veja dados do Comprador cadastrados',
            searchTerms: ['Dados','Comprador'],
            hasRole: 'role/dadosComprador'
        },
        component: () => import('@/views/Comprador/dadosComprador')
    },
]